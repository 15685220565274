<script setup lang="ts">
import type { CmsElementBioCircle } from "~/composables/useCustom";
import { Carousel, Slide, Navigation } from "vue3-carousel";

const props = defineProps<{
  content: CmsElementBioCircle;
}>();

const {
  Testimonials,
  boxLink,
  headlineText,
  toplineText,
  ratingStars,
  ratingStarsNumber,
} = props.content.data;

const settings = {
  itemsToShow: 1,
  itemsToScroll: 1,
  wrapAround: false,
  snapAlign: "start",
  autoplay: 0,
  transition: 600,
  pauseAutoplayOnHover: true,
  breakpoints: {
    // 200px and up
    200: {
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: "center",
    },
    // 400px and up
    400: {
      itemsToShow: 1,
      itemsToScroll: 1,
      snapAlign: "start",
    },
    600: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    900: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    1200: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    1600: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
    1900: {
      itemsToShow: 2,
      itemsToScroll: 2,
      snapAlign: "start",
    },
  },
};

const productSlider = ref(null);
const currentSlide = ref(0);

const slideTo = (val: any) => {
  currentSlide.value = val;
};
</script>
<template>
  <section class="relative">
    <div class="container px-0 xl:px-5">
      <div class="md:flex flex-wrap md:flex-row md:items-center">
        <div class="md:w-2/5 pr-10 mb-10 md:mb-0">
          <h6 v-if="toplineText" class="sub-title">
            {{ toplineText }}
          </h6>
          <h2
            v-if="headlineText"
            class="section-title mb-[1.25rem]"
          > {{ headlineText }}</h2>
          <div
            class="w-[14.375rem] h-[1.875rem] mb-2.5 bg-empty-stars bg-no-repeat bg-[length:auto_100%]"
          >
            <div
              :style="`width: ${(ratingStarsNumber / 5) * 100}%`"
              class="h-full bg-field-stars bg-no-repeat bg-[length:auto_100%]"
            ></div>
          </div>
          <p class="text-skin-secondary block p-1">
            {{ ratingStarsNumber }} {{ $t("from") }} 5.0 {{ $t("star_rating") }}
          </p>
          <SharedButton
            v-if="boxLink" 
            :button="boxLink"
            :hasArrow="true"
            :class="'btn mt-6'"
          ></SharedButton>
        </div>
        <div class="md:w-3/5">
          <div
            id="TestimonialsCarousel"
            class="-mx-2.5 px-3.5 laptop:px-0 md:min-w-[55vw] md:[&>.slick-list>.slick-track>.slick-slide]:opacity-0 md:[&>.slick-list>.slick-track>.slick-slide.slick-active]:opacity-100"
          >
            <Carousel v-bind="settings" ref="productSlider" v-model="currentSlide">
              <template v-for="test of Testimonials" :key="test.id">
                <Slide v-if="test.boxText" class="px-2.5 h-full">
                  <div class="w-full bg-skin-white p-6 rounded">
                    <img src="/icons/icon-quote.svg" alt="icon quote" class="mb-5" />
                    <div
                      class="text-sm prose-p:text-sm prose-p:text-skin-secondary text-left"
                    >
                      {{ test.boxText }}
                    </div>
                    <div class="flex items-center gap-x-3 mt-5">
                      <div
                        class="w-[3.44rem] h-[3.44rem] flex-shrink-0 rounded-full overflow-hidden bg-skin-body"
                      >
                        <NuxtImg
                          format="webp"
                          loading="lazy"
                          v-if="test.boxImage"
                          :src="test.boxImage.media.url"
                          alt=""
                          class="w-full h-full rounded-full object-cover"
                          width="60"
                        />
                      </div>
                      <div>
                        <h4 class="font-bold text-skin-secondary mb-1 mt-1 text-left">
                          {{ test.boxName }}
                        </h4>
                        <p class="mb-0 text-skin-secondary text-sm text-left">
                          {{ test.boxPosition }}
                        </p>
                      </div>
                    </div>
                  </div>
                </Slide>
              </template>

              <template #addons>
                <div
                  class="carousel__navigation-button z-0 absolute top-2/4 max-w-[100rem] flex justify-between -left-[30px] -right-[30px] mx-auto px-3 [&>button]:static [&>button]:m-0 slick-arrow slick-next"
                >
                  <Navigation>
                    <template #next>
                      <img
                        src="assets/icons/slide-right.svg"
                        alt="Next"
                        class="w-6 h-6"
                      />
                    </template>
                    <template #prev>
                      <img
                        src="assets/icons/slide-left.svg"
                        alt="Previous"
                        class="w-6 h-6"
                      />
                    </template>
                  </Navigation>
                </div>
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.carousel__navigation-button :deep(.carousel__prev),
.carousel__navigation-button :deep(.carousel__next) {
  @apply bg-skin-icon text-skin-white w-10 h-10 rounded-full flex items-center justify-center mr-0;
  pointer-events: all;
}

.carousel__navigation-button {
  z-index: 0;
  pointer-events: none;
}
</style>
